const Deals = [
    {
        image: './assets/image/Leftimage.png',
    },
    {
        image: './assets/image/DealsRightTop.png',
    },
    {
        image: './assets/image/rightbottom.png',
    },
]

export default Deals;