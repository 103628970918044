const TopCategory = [
    {
        Category: 'Wraps & Rolls',
        image: 'https://assets.limetray.com/assets/user_images/menus/compressed/1611728198_PaneerChillyWrap.jpg',
    },
    {
        Category: 'Chinese',
        image: 'https://assets.limetray.com/assets/user_images/menus/compressed/1611038138_SpringRoll.jpg',
    },
    {
        Category: 'Pasta',
        image: 'https://assets.limetray.com/assets/user_images/menus/compressed/1611549340_AlfredoPasta.jpg',
    },
    {
        Category: 'South Indian',
        image: 'https://assets.limetray.com/assets/user_images/menus/compressed/1611904788_PaneerDosa.jpg',
    },
]

export default TopCategory;